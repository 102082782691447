/* eslint-disable import-x/no-internal-modules */
import { TextField, Typography } from '@mui/material'
import { ButtonEx } from '@xylabs/react-button'
import { FlexCol, FlexRow } from '@xylabs/sdk-react'
import { WebAppPage } from '@xyo-network/react-webapp'
import React, { useMemo, useState } from 'react'

import { elonStarPatchYellow } from '../colors.ts'
import backgroundImage from '../img/background.png'
import elonLogo from '../img/elon-logo.png'
import rectangleLights from '../img/rectangle-lights.png'
import { questions } from './questions.ts'
import { getHeight, useCurrentWidth } from './useCurrentWidth.ts'

export const Home: React.FC = () => {
  const width = useCurrentWidth()
  let height = getHeight()
  const vertical = height > width
  const [slug, setSlug] = useState<keyof typeof questions | null>('hot-girl')
  const [showBeer, setShowBeer] = useState<boolean | null>(null)
  const [refresh, setRefresh] = useState(0)

  useMemo(() => {
    if (slug === 'out-of-here-after-drink') {
      setShowBeer(true)
      setTimeout(() => {
        setShowBeer(false)
      }, 2000)
    }
  }, [slug, refresh])

  console.log('slug', slug)

  const question = slug ? questions[slug] : null

  console.log('question', question)

  height = vertical ? width * 1.78 : width * 0.56

  return (
    <WebAppPage
      container="sm"
      padding={0}
      sx={{
        backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center', backgroundSize: 'cover',
      }}
      justifyContent="center"
      alignItems="center"
    >
      <FlexCol>
        <FlexRow marginY={2}>
          <img src={elonLogo} width="100%" />
        </FlexRow>
        {question
          ? (
              <FlexCol
                justifyContent="flex-start"
                width="100%"
              >
                <FlexRow position="absolute">
                  <img src={rectangleLights} width="100%" />
                </FlexRow>
                {showBeer
                  ? (
                      <FlexRow padding={1}>
                        <img src="/img/beer.gif" width="256px" />
                      </FlexRow>
                    )
                  : (
                      <FlexCol padding={2} justifyContent="center" height="200px">
                        <Typography
                          align="center"
                          color="yellow"
                          textTransform="uppercase"
                          fontFamily="monospace"
                          lineHeight="32px"
                          fontSize="30px"
                        >
                          {question.message}
                        </Typography>
                        <FlexRow gap={2} padding={1}>
                          {Object.entries(question.options ?? {}).map(([key, value]) => (
                            <ButtonEx
                              key={key}
                              paddingX={4}
                              sx={{
                                fontSize: '18px', color: 'black', backgroundColor: elonStarPatchYellow, textTransform: 'uppercase', fontFamily: 'monospace',
                              }}
                              onClick={() => {
                                setShowBeer(null)
                                setRefresh(refresh + 1)
                                setSlug(value)
                              }}
                              variant="contained"
                            >
                              {key}
                            </ButtonEx>
                          ))}
                          {question.entry === 'phone-capture'
                            ? (
                                <FlexRow gap={1}>
                                  <TextField type="tel" />
                                  <ButtonEx
                                    variant="contained"
                                    onClick={() => {
                                      if (question.postEntry) {
                                        setSlug(question.postEntry)
                                      }
                                    }}
                                  >
                                    Send
                                  </ButtonEx>
                                </FlexRow>
                              )
                            : null}
                          {question.entry === 'email-capture'
                            ? (
                                <FlexRow gap={1}>
                                  <TextField type="email" />
                                  <ButtonEx
                                    variant="contained"
                                    onClick={() => {
                                      if (question.postEntry) {
                                        setSlug(question.postEntry)
                                      }
                                    }}
                                  >
                                    Send
                                  </ButtonEx>
                                </FlexRow>
                              )
                            : null}
                        </FlexRow>
                      </FlexCol>
                    )}
              </FlexCol>
            )
          : null}
        <FlexRow padding={16} />
      </FlexCol>
    </WebAppPage>
  )
}
