import { useTheme } from '@mui/material'
import {
  BasePage, FlexCol, FlexRow,
} from '@xylabs/sdk-react'
import React from 'react'

// eslint-disable-next-line import-x/no-internal-modules
import elonImage from '../img/elon.png'
import { getHeight, useCurrentWidth } from './useCurrentWidth.ts'

export const ComingSoon: React.FC = () => {
  const theme = useTheme()
  const width = useCurrentWidth()
  let height = getHeight()
  const vertical = height > width

  height = vertical ? width * 1.78 : width * 0.56

  return (
    <BasePage appBar={<FlexRow />} bgcolor={theme.palette.primary.main}>
      <FlexCol width={width}>
        <FlexCol height={height}>
          <img src={elonImage} width={300} />
        </FlexCol>
      </FlexCol>
    </BasePage>
  )
}
