import React from 'react'
import { Route, Routes } from 'react-router-dom'

import {
  ComingSoon, Error404, Home,
} from './index.ts'

export const SiteRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ComingSoon />} />
      <Route path="/x" element={<Home />} />
      <Route element={<Error404 />} path="*" />
    </Routes>
  )
}
