import { useTheme } from '@mui/material'
import type { AppBarExProps } from '@xylabs/sdk-react'
import { AppBarEx } from '@xylabs/sdk-react'
import React from 'react'

import { Title } from './Title.tsx'

export const CustomAppBar: React.FC<AppBarExProps> = () => {
  const theme = useTheme()
  return <AppBarEx style={{ backgroundColor: theme.palette.primary.main }} contextToolbar={<Title />} systemToolbar={<Title />} position="fixed"></AppBarEx>
}
