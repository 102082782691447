export interface Question {
  entry?: string
  message: string
  options?: Record<string, string | null>
  postEntry?: string
}

export const questions: Record<string, Question> = {
  'hot-girl': { message: 'Are you a hot girl?', options: { Yes: 'your-number', No: 'the-boys' } },
  'your-number': { message: 'Cool. Can I have your number?', options: { Yes: 'capture-number', No: 'please-number' } },
  'lets-hangout': { message: 'Sick. Let hang at my place.' },
  'capture-number': {
    message: 'Sick. What is it?', entry: 'phone-capture', postEntry: 'out-of-here',
  },
  'please-number': { message: 'Please?', options: { 'Ok Fine': 'capture-number', 'Still No': 'get-email' } },
  'the-boys': { message: 'Oh, are you one of the boys?', options: { 'Yea Bro': 'lets-hangout', 'No': 'autograph' } },
  'autograph': { message: 'Oh, just a fan. Want an autograph?', options: { 'I guess': 'im-busy', 'Who are you?': 'wasting-my-time' } },
  'im-busy': { message: "Sorry, I'm busy" },
  'wasting-my-time': { message: 'Ugh! You are wasting my time.' },
  'get-email': { message: "Come on baby, how 'bout your email?", options: { 'Sure': 'capture-email', 'Go Away': 'not-hot' } },
  'not-hot': { message: "Ugh! Fine. You're not that hot anyway" },
  'capture-email': {
    message: 'Sick. What is it?', entry: 'email-capture', postEntry: 'out-of-here',
  },
  'out-of-here': { message: 'Wanna get out of here?', options: { 'Sure, why not': null, 'At least buy me a drink first': 'out-of-here-after-drink' } },
  'out-of-here-after-drink': { message: 'Wanna get out of here?', options: { 'Lets go': null, 'I need another drink first': 'out-of-here-after-drink' } },
}
