import { useEffect, useState } from 'react'

export const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

export const useCurrentWidth = () => {
  // save current window width in the state object
  const [width, setWidth] = useState(() => getWidth())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    const resizeListener = () => {
      // eslint-disable-next-line @eslint-react/web-api/no-leaked-timeout
      setTimeout(() => setWidth(getWidth()), 150)
    }
    // set resize listener
    window.addEventListener('resize', resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return width
}

export const getHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
