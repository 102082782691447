import { Box } from '@mui/material'
import { InvertibleThemeProvider } from '@xylabs/react-invertible-theme'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { SiteRoutes } from '../Pages/index.ts'
import { themeOptions } from '../theme/index.ts'
import { checkDomain } from './checkDomain.ts'

checkDomain()

export const App: React.FC = () => {
  return (
    <InvertibleThemeProvider options={themeOptions}>
      <BrowserRouter>
        <Box className="App">
          <Box>
            <SiteRoutes />
          </Box>
        </Box>
      </BrowserRouter>
    </InvertibleThemeProvider>
  )
}
