import type { ToolbarProps } from '@mui/material'
import { Toolbar, Typography } from '@mui/material'
import { ButtonEx } from '@xylabs/sdk-react'
import React from 'react'

export const Title: React.FC<ToolbarProps> = (props) => {
  return (
    <Toolbar {...props}>
      <ButtonEx color="inherit" href="/">
        <Typography variant="h4">Elon Lust</Typography>
      </ButtonEx>
    </Toolbar>
  )
}
